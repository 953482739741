body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
figure img{
  max-width: 100%;
  max-height: auto;
}
.marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: transparent;
}

.marker:hover {
  transform: scale(1.2);
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  padding: 10px;
  border-radius: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.custom-popup .mapboxgl-popup-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 16px;
  font-family: Arial, sans-serif;
}

.custom-popup .mapboxgl-popup-tip {
  border-top-color: #ffffff;
}

.custom-popup .popup-content h3 {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: bold;
}

.custom-popup .popup-content p {
  margin: 0;
  font-size: 14px;
}
